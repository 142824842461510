<template>
    <div class="copyright">
      <p>&copy; {{ currentYear }} Sciphotonics. All rights reserved.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CopyRight',
    data() {
      return {
        currentYear: new Date().getFullYear()
      }
    }
  }
  </script>
  
  <style scoped>
    .copyright {
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
    }
  </style>