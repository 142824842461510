<template>
  <div class="home">
    <h1>Welcome to Sciphotonics!</h1>
    <p>Coming soon</p>
    <copyright />
  </div>
</template>

<script>
import Copyright from '@/components/Copyright'

export default {
  name: 'HomeComponent',
  components: {
    Copyright
  }
}
</script>